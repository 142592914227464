.row {
  display: flex;
  flex-wrap: wrap;
  padding: 2vh;
  margin: 2vmin;
  justify-content: center;
}

.green,
code {
  color: #228B22;
  white-space: pre-wrap;
}

.answer{
  color:white;
  height: 50px;
  width: 100px;
  font-size: 30px;
}

.smallButton {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  border: none;
  width: 30vmin;
  height: 15vmin;
  min-width: 200px;
  transition: transform 0.2s ease-in-out;
}

.smallButton:hover {
  transform: scale(0.9);
}

.smallButton:focus{
  outline-color: #228B22;
}

.fadeIn{
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
   -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
       animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}