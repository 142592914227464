
.icon{
  margin: 5px;
  color: white;
}

.footer {
  width: 100%;
  padding-top: 2vh;
  background-color: grey;
  font-size: calc(8px + 1vmin);
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.footerLink {
  color:white;
  text-decoration: none;
}

.cookieBar {
  max-width: 90%;
}