.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  /* background-image: linear-gradient(to right, #282c34, gray); */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  height: 15vmin;
}