
.nodeButton {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  border: none;
  transition: transform 0.2s ease-in-out;
}

.nodeButton:hover {
  transform: scale(0.9);
}

.bottomButton {
  width: 30vmin;
  height: 30vmin;
  min-width: 200px;
}

.middleButton {
  width: 30vmin;
  height: 15vmin;
  min-width: 200px;
}

.green
{
  color: #228B22;
}