
.App-link {
  color: #61dafb;
}

.description{
  width: 80vw;
  text-align: left;
}

.tutorial{
  margin: 10;
}

.math { font-size: calc(3vw)} 

.video{
  width: 70vmin;
  height: 40vmin;
}

.green,
code {
  color: #228B22;
  white-space: pre-wrap;
}
